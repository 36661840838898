<template>
  <div class="review-reg-modal-container">
    <sweet-modal ref="modal"
                 overlay-theme="dark"
                 width="700px"
                 hideCloseButton
                 enable-mobile-fullscreen>
      <div class="modal-container">
        <div class="modal-header">
          <div class="body2-medium">후기작성</div>
          <img src="/static/icon/fi_close_outline.svg" class="svg-sub3 unselect" @click="$refs.modal.close()">
        </div>
        <div class="modal-content">
          <div class="flex-align">
            <img :src="product.img" :alt="product.name" class="img-product">
            <div>
              <div class="size-15 weight-500">{{ product.name }}</div>
              <div class="body5 sub3" style="margin-top:2px">{{ product.simple_desc }}</div>
            </div>
          </div>
          <template v-if="rate">
            <div class="divider"></div>
            <div class="body4 sub text-center margin-bottom-8">별점</div>
            <div class="flex-center">
              <star-rating :rating="param.rate"
                           @rating-selected="res => param.rate = res"
                           active-color="#ff5722"
                           :star-size="32"
                           :increment="0.5"
                           :padding="5"
                           :star-points="[20,34, 30,40, 31,40, 32,40, 33,38, 33,37, 31,25, 39,18, 40,16, 40,15, 39,14, 38,13, 27,12, 22,1, 21,0, 20,0, 19,0, 18,1, 13,12, 2,13, 1,14, 0,15, 0,16, 1,18, 9,25, 7,37, 7,38, 8,40, 9,40, 10,40, 20,34]"
                           :show-rating="false"></star-rating>
            </div>
          </template>

          <div class="body4 sub text-center margin-top-20 margin-bottom-8">후기 내용</div>
          <textarea v-model="param.content"
                    @keyup="e => param.content = e.target.value"
                    placeholder="내용을 입력해주세요"></textarea>
          <div class="body4 sub text-center margin-top-24 margin-bottom-8">후기사진 (최대 5장)</div>
          <div class="flex-center">
            <input-img-uploader-array :value.sync="param.imgs"></input-img-uploader-array>
          </div>
          <button class="button is-primary button-save" @click="clickSave">저장</button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import StarRating from "vue-star-rating/src/star-rating";
  import InputImgUploaderArray from "../module/InputImgUploaderArray";
  import { SweetModal } from 'sweet-modal-vue'
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "ReviewRegModal",
    mixins: [
      UserAPIMixin,
      ServiceAdminAPIMixin
    ],
    components: {
      InputImgUploaderArray,
      SweetModal,
      StarRating
    },
    props: {
      product: {
        type: Object
      },
      order_id: {
        type: Number,
        default: 0
      },
      isAdmin: {
        type: Boolean,
        default: true
      },
      rate: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        param: {
          product_id: 0,
          title: '',
          content: '',
          imgs: [],
          rate: 0,
        }
      }
    },
    methods: {
      open() {
        this.param = {
          product_id: this.product.id,
          title: '',
          content: '',
          imgs: [],
          rate: 0,
        };
        this.$refs.modal.open();
      },
      clickSave() {
        this.param.imgs = this.param.imgs.filter(i => i !== '');
        if(this.isAdmin) {
          this.param.service_id = this.userService.id;
          this.$store.commit('setLoading', true);
          this.request.serviceAdmin.post(
            `service/${this.userService.id}/product/${this.product.id}/review`, this.param).then(res => {
            this.$store.commit('setLoading', false);
            if(res.status === 200) {
              this.toast('후기가 저장되었습니다.');
              this.$refs.modal.close();
              this.$emit('done');
            }
          }).catch(e=>{
            console.log(e);
            this.$store.commit('setLoading', false);
          });
        } else {
          if(this.order_id>0) {
            this.param.order_id = this.order_id;
          }
          this.$store.commit('setLoading', true);
          this.request.user.post('product/review', this.param).then(res => {
            this.$store.commit('setLoading', false);
            if(res.status === 200) {
              this.toast('후기가 저장되었습니다.');
              this.$refs.modal.close();
              this.$emit('done');
            }
          }).catch(e=>{
            console.log(e);
            this.$store.commit('setLoading', false);
          });
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .modal-header
    background-color $gray2
    padding 16px 24px
    display flex
    justify-content space-between
    align-items center
  .modal-container
    width 700px
    max-width 100vw
    min-height 673px
    max-height 90vh

  .modal-content
    padding 20px

  .divider
    background-color $gray1
    width 100%
    height 1px
    margin 24px 0

  .img-product
    width 80px
    height 80px
    border-radius 8px
    margin-right 12px

  textarea
    border 1px solid $gray1
    border-radius 8px
    padding 12px 16px
    height 150px
    width 100%

  .button-save
    height 48px
    width 100%
    margin-top 40px
</style>
<style lang="stylus">
  .review-reg-modal-container
    .sweet-modal.is-alert .sweet-content
      padding 0 !important
      text-align left
    .sweet-modal-overlay
      -webkit-perspective initial

  @media screen and (max-width: 600px)
    .review-reg-modal-container
      .sweet-modal.is-mobile-fullscreen.is-visible
        border-radius 0 !important
</style>
